<template>
    <div v-if="obj.index || (obj.index == 0 && obj.index != null)">
        <div>

            <div class="flex flex-col border border-gray-300 rounded-lg" :class="selected ? 'bg-yellow-default opacity-60':'bg-gray-100'">

                <div class="w-full overflow-hidden rounded-lg cursor-pointer"
                v-if="buttons.includes('deactiveSelection') && !deactiveButtons.includes('deactiveSelection')"
                @click="setEvent('deactiveSelection')"
                >
                    <div class="bg-yellow-default p-2 w-10 h-10 flex items-center justify-center rounded-full rounded-tl-lg">
                        <CheckOne size="22" />
                    </div>
                </div>

                <div class="w-full overflow-hidden rounded-lg cursor-pointer flex space-x-2 justify-center items-center"
                v-if="buttons.includes('activeSelection') && !deactiveButtons.includes('activeSelection')"
                @click="setEvent('activeSelection')"
                >
                    <div class="bg-secondary text-white p-2 w-10 h-10 flex items-center justify-center rounded-full rounded-tl-lg">
                        <CloseOne size="22" />
                    </div>
                    <div class="text-xs w-full">
                        Gelmedi
                    </div>
                </div>

                <div class="w-full -p-2 flex mt-1">
                    <div class="w-full py-2 px-4 bg-primary-300 -ml-2 rounded-l-lg cursor-pointer text-white"
                    v-if="buttons.includes('trueAnswer') && !deactiveButtons.includes('trueAnswer')"
                    @click="setEvent('trueAnswer')"
                    >
                        Doğru
                    </div>
                    <div class="w-full py-2 px-4 bg-secondary -mr-2 rounded-r-lg cursor-pointer text-white"
                    v-if="buttons.includes('falseAnswer') && !deactiveButtons.includes('falseAnswer')"
                    @click="setEvent('falseAnswer')"
                    >
                        Yanlış
                    </div>
                </div>

                <div class="px-8 py-4 text-primary-300 flex items-center justify-center relative" v-if="avatar">
                    <div class="opacity-60">
                        <User size="60" />
                    </div>
                    <div class="absolute right-7 bottom-3 text-white p-3 bg-secondary rounded-full cursor-pointer"
                    v-if="buttons.includes('applause') && !deactiveButtons.includes('applause')"
                    @click="setEvent('applause')">
                        <Clap size="15"/>
                    </div>
                </div>

                <div class="flex justify-around items-center p-2 -mt-4" v-if="info">
                    <div class="flex items-center justify-center space-x-1 rounded-lg px-2 py-1 text-secondary">
                        <div><Pennant size="15"/></div>
                        <div>{{ obj?.selectedSize || 0 }}</div>
                    </div>
                    <div class="flex items-center justify-center space-x-1 rounded-lg px-2 py-1 text-primary-darkest">
                        <div><Gift size="15"/></div>
                        <div>{{ obj?.point || 0 }}</div>
                    </div>
                </div>

                <div class="py-2 px-4 flex items-center justify-center bg-secondary text-xs rounded-b-lg text-white font-bold">
                    {{ obj?.full_name || `${(obj.index + 1)}. Öğrenci` }}
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>
import { defineProps, defineEmits} from 'vue';
import {GirlOne, Pennant, Gift, Clap, User, CheckOne, CloseOne} from '@icon-park/vue-next';

const emit = defineEmits(['event'])
//buttons => ['deactiveSelection','activeSelection', 'trueAnswer', 'falseAnswer', 'applause']
const props = defineProps({
    obj: {type: Object},
    event:{},
    deactiveButtons:{type: Array, default: []},
    buttons: { type: Array, required: false, default: [] },
    info: {type: Boolean, default: false},
    selected: {type: Boolean, default: false},
    avatar: {type: Boolean, default: true}
})

const buttonPosition = [
    {bottom: 4, right: 20},
    {bottom: 20, right: 2},
    {bottom: 42, right: -2},
    {bottom: 66, right: 2},
    {bottom: 80, right: 20}
]

const buttonOptions = {
    deactiveSelection: {color: 'bg-red-600', title: 'H'},
    activeSelection: {color: 'bg-green-600', title: 'E'},
    trueAnswer: {color: 'bg-primary-300', title: 'D'},
    falseAnswer: {color: 'bg-red-600', title: 'Y'},
    applause: {color: 'bg-gray-600', title: 'A'}
}


const setEvent = (type) => {
    props.event.value = {type, obj: props.obj}
    emit('event', type)

    //doğru yada yanlış bir kere seçildikten sonra kapanmasını sağlıyor
    if (['trueAnswer', 'falseAnswer'].includes(type)) {
        props.deactiveButtons.push('trueAnswer')
        props.deactiveButtons.push('falseAnswer')
    }
}



</script>
