<template>
    <div class="inline-block group cursor-pointer relative w-4 h-full text-center">
        <svg v-if="props.icon === 'question'" class="w-4 h-4 fill-current" :class="props.color" viewBox="0 0 512 512">
            <path d="M504 256c0 136.997-111.043 248-248 248S8 392.997 8 256C8 119.083 119.043 8 256 8s248 111.083 248 248zM262.655 90c-54.497 0-89.255 22.957-116.549 63.758-3.536 5.286-2.353 12.415 2.715 16.258l34.699 26.31c5.205 3.947 12.621 3.008 16.665-2.122 17.864-22.658 30.113-35.797 57.303-35.797 20.429 0 45.698 13.148 45.698 32.958 0 14.976-12.363 22.667-32.534 33.976C247.128 238.528 216 254.941 216 296v4c0 6.627 5.373 12 12 12h56c6.627 0 12-5.373 12-12v-1.333c0-28.462 83.186-29.647 83.186-106.667 0-58.002-60.165-102-116.531-102zM256 338c-25.365 0-46 20.635-46 46 0 25.364 20.635 46 46 46s46-20.636 46-46c0-25.365-20.635-46-46-46z"></path>
        </svg>
        <svg v-if="props.icon === 'info'" class="w-4 h-4 fill-current" viewBox="0 0 512 512">
            <path d="M256 8C119.043 8 8 119.083 8 256c0 136.997 111.043 248 248 248s248-111.003 248-248C504 119.083 392.957 8 256 8zm0 110c23.196 0 42 18.804 42 42s-18.804 42-42 42-42-18.804-42-42 18.804-42 42-42zm56 254c0 6.627-5.373 12-12 12h-88c-6.627 0-12-5.373-12-12v-24c0-6.627 5.373-12 12-12h12v-64h-12c-6.627 0-12-5.373-12-12v-24c0-6.627 5.373-12 12-12h64c6.627 0 12 5.373 12 12v100h12c6.627 0 12 5.373 12 12v24z"></path>
        </svg>
        <div
            class="absolute p-2 w-48 opacity-0 bg-secondary text-white text-center text-xs font-bold rounded-lg z-10 group-hover:opacity-100 pointer-events-none"
            :class="{
                'bottom-full left-1/2 -translate-x-1/2 mb-2': props.position === 'top',
                'top-full left-1/2 -translate-x-1/2 mt-2': props.position === 'bottom',
                'right-full top-1/2 -translate-y-1/2 mr-2': props.position === 'left',
                'left-full top-1/2 -translate-y-1/2 ml-2': props.position === 'right'
            }">
            {{ props.text }}
            
            <svg v-if="props.position === 'top'" class="absolute text-secondary h-2 w-full left-0 top-full" x="0px"
                 y="0px" viewBox="0 0 255 255"><polygon class="fill-current" points="0,0 127.5,127.5 255,0"/></svg>
            
            <svg v-if="props.position === 'bottom'" class="absolute text-secondary h-2 w-full left-0 -top-2" x="0px"
                 y="0px" viewBox="0 0 255 255"><polygon class="fill-current" points="0,127.5 127.5,0 255,127.5"/></svg>
            
            <svg v-if="props.position === 'left'" class="absolute text-secondary h-3 w-2 -right-2 top-1/2 -translate-y-1/2" x="0px"
                 y="0px" viewBox="0 0 255 255"><polygon class="fill-current" points="0,0 255,127.5 0,255"/></svg>
            
            <svg v-if="props.position === 'right'" class="absolute text-secondary h-3 w-2 -left-2 top-1/2 -translate-y-1/2" x="0px"
                 y="0px" viewBox="0 0 255 255"><polygon class="fill-current" points="255,0 0,127.5 255,255"/></svg>
        </div>
    </div>
</template>

<script setup>
    const props = defineProps(['icon', 'position', 'text', 'color']);
</script>
